<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template v-if="employee" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mb--md mt--xl" />
      <div v-if="employee.id">
        <getecma-header size="lg">Editar Funcionário</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-employee-edit-information @save="save" />
      </div>
      <div v-if="!employee_id">
        <getecma-header size="lg">Adicionar Funcionário</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-employee-create-information @create="save" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError, toastSuccess } from '@/services/toastService';
import { goToOpenEmployee, goToEmployeeForbiddenPage } from '@/modules/employee/employee.routes'; // eslint-disable-line
import { getters } from '@/modules/user/user.store';
import { getEmployeeById, updateEmployeeInformation, createNewEmployee } from '@/modules/employee/employee.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { EMPLOYEES_URL } from '@/modules/employee/employee.constants';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaEmployeeEditInformation from '@/modules/employee/components/EmployeeEditComponent.vue';
import GetecmaEmployeeCreateInformation from '@/modules/employee/components/EmployeeCreateComponent.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaEmployeeEditPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaEmployeeEditInformation,
    GetecmaEmployeeCreateInformation,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  provide() {
    const employeeEditVm = {};
    Object.defineProperty(employeeEditVm, 'employee', {
      get: () => this.employee,
    });
    return { employeeEditVm };
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Funcionários', path: EMPLOYEES_URL.path },
        { name: 'Adicionar', path: this.$route.path },
      ],
      currentUser: getters.getUser(),
      employee: null,
      createdEmployee: null,
      fullscreenLoading: false,
    };
  },
  computed: {
    employee_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    if (this.employee_id) {
      this.itemsBreadcrumb[2].name = 'Editar';
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToEmployeeForbiddenPage(this.$router, false);
      this.onFetch();
    } else {
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToEmployeeForbiddenPage(this.$router, true);
      this.onFetch();
    }
  },
  methods: {
    onFetch() {
      if (this.employee_id) {
        getEmployeeById(this.employee_id)
          .then(data => {
            this.employee = data;
          })
          .catch(() => toastError('Erro ao obter funcionário por ID'));
      } else {
        this.employee = {
          name: '',
        };
      }
    },
    save() {
      if (this.employee_id) {
        this.fullscreenLoading = true;
        updateEmployeeInformation(this.employee)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Funcionário salvo!');
            goToOpenEmployee(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar o perfil do funcionário');
            this.fullscreenLoading = false;
          });
      } else {
        this.onCreateNewEmployee(this.employee);
      }
    },
    onCreateNewEmployee(employee) {
      createNewEmployee(employee)
        .then(data => {
          goToOpenEmployee(this.$router, data);
        })
        .catch(() => {
          toastError('Erro ao salvar o funcionário');
        });
    },
  },
};
</script>
