<template>
  <div>
    <getecma-form v-if="employee" :submit="create">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="employee.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Cláudio da Silva" />
        </div>
      </div>
      <div class="mt--xl mb--md d--flex justify-content-end">
        <getecma-button
          class="fs--xs"
          :round="false"
          size="lg"
          @click="goHistoryBack()">
          Cancelar
        </getecma-button>
        <getecma-button
          native-type="submit"
          class="fs--xs ms--md"
          :round="false"
          bg-color="success"
          size="lg">
          Criar
        </getecma-button>
      </div>
    </getecma-form>
  </div>
</template>

<script>
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';

export default {
  name: 'GetecmaEmployeeCreateInformation',
  components: {
  },
  inject: ['employeeEditVm'],
  data() {
    return {
      employee: this.employeeEditVm.employee,
      performer: getters.getUser(),
    };
  },
  methods: {
    goHistoryBack,
    create() {
      this.$emit('create');
    },
  },
};
</script>
